'use client';
import { useClientSideSentry } from '@/hooks/useClientSideSentry/useClientSideSentry';
import { ErrorPage as GrimmeErrorPage } from '@grimme/components';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ErrorPage({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const router = useRouter();
  const { t } = useTranslation();

  const Sentry = useClientSideSentry();

  useEffect(() => {
    // to capture and report errors thrown in any page.tsx
    Sentry.captureException(error);
  }, [Sentry, error]);

  return (
    // copied for the app router from the original pages/500.tsx.
    <GrimmeErrorPage
      cancelButtonText={t(`myGRIMME_group_error_page_back`)}
      confirmButtonText={t(`myGRIMME_group_error_dialog_go_to_landing_page`)}
      onCancel={() => router.back()}
      onConfirm={() => router.push('/')}
      paragraph={t(`myGRIMME_group_error_page_paragraph`)}
      title={t(`myGRIMME_group_error_page_title`)}
    />
  );
}
